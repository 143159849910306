@charset "UTF-8";
table .cell-action {
  text-align: left; }

table .cell-action a {
  text-decoration: none; }

table .cell-action .cell-action-delete a {
  color: #d9534f; }

.inapp-processing {
  position: absolute;
  width: 100%;
  padding-top: 20px;
  text-align: center;
  font-size: 1.2em;
  margin: 0;
  height: 100%;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.6);
  z-index: 100000; }

.inapp-processing-message-container {
  top: 35%;
  color: #fff;
  position: relative;
  width: 100%;
  background-color: #232323;
  background-color: rgba(0, 0, 0, 0.8);
  padding: 20px; }

.notifications {
  display: none; }

.exit-button {
  display: inline-block;
  margin-left: 20px; }

.exit-button a {
  display: inline-block;
  text-decoration: none; }

.add-user-div {
  margin-bottom: 10px; }

.smart-form .col header {
  margin: 0; }

.pt10, .smart-form .pt10 {
  padding-top: 10px; }

.pt15, .smart-form .pt15 {
  padding-top: 15px; }

.pt20, .smart-form .pt20 {
  padding-top: 20px; }

.mb20, .smart-form .mb20 {
  margin-bottom: 20px; }

.smart-form fieldset {
  padding: 0 14px 5px; }

.licenses-alert {
  vertical-align: middle;
  position: relative;
  background-color: #23a3f1;
  padding: 5px 0 4px 13px;
  color: #FFFFFF; }
  .licenses-alert.la-past {
    background-color: #ff0000; }
  .licenses-alert.la-future {
    background-color: #23a3f1; }
  .licenses-alert ul {
    margin: 0;
    padding: 0;
    list-style: none; }
    .licenses-alert ul li {
      list-style-type: none; }

li.optgroup {
  padding: 2px 8px;
  color: #000000;
  background: #e2e2e2; }

.header-dropdown-list.bl-dropdown-list > li {
  margin-top: 1px !important; }

.dropdown-menu.no-padding {
  padding: 0; }

.bl-div {
  margin-left: 6px; }

a.dropdown-toggle {
  margin-top: 9px !important; }
  a.dropdown-toggle.bl-toggle {
    border-radius: 2px;
    cursor: default !important;
    display: inline-block;
    font-weight: bold;
    height: 30px;
    line-height: 24px;
    min-width: 30px;
    padding: 2px 7px;
    text-align: center;
    text-decoration: none !important;
    -moz-user-select: none;
    -webkit-user-select: none;
    background-color: #f8f8f8;
    background-image: -webkit-gradient(linear, left top, left bottom, from(#f8f8f8), to(#f1f1f1));
    background-image: -webkit-linear-gradient(top, #f8f8f8, #f1f1f1);
    background-image: -moz-linear-gradient(top, #f8f8f8, #f1f1f1);
    background-image: -o-linear-gradient(top, #f8f8f8, #f1f1f1);
    background-image: linear-gradient(top, #f8f8f8, #f1f1f1);
    border: 1px solid #bfbfbf;
    color: #6D6A69;
    font-size: 17px;
    margin: 10px 0 0 0; }
    a.dropdown-toggle.bl-toggle span {
      font-size: 13px;
      font-weight: normal;
      height: 30px;
      display: inline-block;
      line-height: 20px; }

.select2-results__option {
  padding-left: 10px; }

.select2 .select2-selection__rendered {
  padding-left: 5px; }

.form-group.has-error span .select2-selection {
  background: #fff0f0;
  border-color: #A90329; }

.error-note {
  font-style: normal;
  font-size: 11px;
  line-height: 15px;
  display: block;
  position: relative;
  color: #D56161;
  margin-top: 6px !important; }
  .error-note ul {
    list-style: none; }

nav ul ul {
  padding: 0 !important; }
  nav ul ul li {
    background: rgba(69, 69, 69, 0.6) !important; }

​.smart-form legend {
  margin: 10px 0 0;
  padding: 8px 15px;
  box-sizing: border-box; }

.total-overlay {
  position: fixed;
  display: block;
  width: 100%;
  /* Full width (cover the whole page) */
  height: 100%;
  /* Full height (cover the whole page) */
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1000000;
  cursor: pointer; }

.spin-loader {
  z-index: 1000001;
  margin: auto auto;
  position: relative;
  top: 50%;
  width: 100%;
  text-align: center; }

ul.ingredients {
  list-style: none; }
  ul.ingredients li {
    list-style-type: none; }

.recipe-add-button {
  width: 35px !important; }

.salable_item_ingredients-collection-actions, .salable_item_edit_ingredients-collection-actions {
  display: none; }

div.production-instructions div.production-instructions-item {
  margin-top: 8px;
  border-radius: 10px;
  border: 1px solid #c8c8c8;
  background-color: #eee;
  padding: 10px 14px; }
  div.production-instructions div.production-instructions-item .prod-close-button {
    font-size: 18px;
    position: absolute;
    top: -20px;
    right: -16px;
    cursor: pointer;
    display: block;
    line-height: 0;
    padding: 11px 3px; }
  div.production-instructions div.production-instructions-item header {
    background: transparent;
    margin: 10px 15px 0;
    font-size: 13px; }

div.production-instructions div.assets-list-wrapper {
  margin-bottom: 0 !important; }

div.assets-list {
  background: transparent;
  padding: 2px 15px; }
  div.assets-list .assets-list-item {
    margin-top: 5px;
    border-radius: 5px;
    background-color: #0AA66E;
    border: 1px solid #099c68; }
    div.assets-list .assets-list-item fieldset.assets-list-fieldset {
      padding-right: 5px;
      border-radius: 5px; }
      div.assets-list .assets-list-item fieldset.assets-list-fieldset.assets-list-fieldset {
        padding: 12px 24px 0 10px !important; }
      div.assets-list .assets-list-item fieldset.assets-list-fieldset .select2-container {
        z-index: 10; }
      div.assets-list .assets-list-item fieldset.assets-list-fieldset section {
        margin-bottom: 0; }
      div.assets-list .assets-list-item fieldset.assets-list-fieldset select.select2-hidden-accessible, div.assets-list .assets-list-item fieldset.assets-list-fieldset select.select2-display-none {
        display: none !important; }
      div.assets-list .assets-list-item fieldset.assets-list-fieldset select.asset-duration-selecthas-error {
        color: #D56161; }
      div.assets-list .assets-list-item fieldset.assets-list-fieldset .asset-close-button {
        font-size: 18px;
        position: absolute;
        top: -11px;
        right: -7px;
        cursor: pointer;
        display: block;
        line-height: 0;
        padding: 11px 3px; }
        div.assets-list .assets-list-item fieldset.assets-list-fieldset .asset-close-button a {
          color: #a90329; }
    div.assets-list .assets-list-item a.assets-action {
      margin-top: 4px; }

form em.invalid {
  color: #D56161; }

.ingredient-percent-value {
  width: 100%;
  height: 100%;
  vertical-align: middle;
  text-align: right;
  margin-top: 6px !important; }

.color-warning {
  color: #ffe211; }

.card-nutritional-value {
  font-weight: bold;
  font-size: 14px; }

.select2-selection__choice__remove {
  min-height: 16px;
  min-width: 17px; }

.select2-container--default .select2-results__option[aria-disabled=true] {
  color: #999; }

ul.impacted-items-list {
  padding-left: 0;
  margin-top: 8px; }

ul.impacted-items-list li {
  display: inline-block;
  float: left;
  border: 1px solid #aaa;
  line-height: 0.82rem;
  background-color: #e4e4e4 !important;
  border-radius: 4px;
  padding: 4px 5px;
  margin: 0 0 3px 3px; }

.select2-selection__choice,
.select2-container-multi .select2-choices .select2-search-choice {
  padding: 1px 28px 1px 8px !important;
  margin: 4px 0 3px 5px !important; }

.select2-selection__choice__remove {
  top: 2px; }

#is-loading-full-overlay {
  z-index: 3000 !important; }

#affected ul {
  padding-left: 0;
  margin-top: 8px;
  list-style: none; }
  #affected ul li {
    list-style: none; }

#clearRange {
  cursor: pointer; }
  #clearRange:hover {
    color: red; }

.note-error {
  color: #D56161; }

.smart-timeline-list > li {
  min-height: 140px; }

.allergen-list {
  margin-left: 13px !important; }
  .allergen-list li {
    display: inline-block;
    font-size: 12px;
    line-height: 12px;
    float: left;
    border: 1px solid #aaa;
    background-color: #e4e4e4 !important;
    border-radius: 4px;
    padding: 3px 5px;
    margin: 0 0 3px 3px; }
